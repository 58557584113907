<template>
  <div class="Top">
    
	<div class="Wrap">
		<div class="Home">
			<i class="el-icon-s-home" @click="$Jump('/')">{{$store.getters.getAppName}}</i>
		</div>
		
		<div class="Nav">
			<span class="Welcome">您好{{$store.getters.getUserName}}，欢迎访问</span>
			<i size="mini" @click="$Jump('/login')" v-if="$store.getters.getToken == ''" class="Red">登录</i>
			<i size="mini" @click="$Jump('/register')" v-if="$store.getters.getToken == ''"><b style="font-weight: normal;margin-right: 5px;" class="el-icon-user"></b>注册</i>
			
			<i v-if="$store.getters.getToken != ''" @click="OpenNoticeListDialog()" class="Message">
				<label class="el-icon-message" :style="IsNoticeUnread ? 'font-size: 1.2rem;cursor: pointer;padding-top: 5px;color:red':'font-size: 1.2rem;cursor: pointer;padding-top: 5px;'"></label></i>
			
			<i size="mini" @click="$Jump('/my/order/list')" v-if="$store.getters.getToken != ''">我的订单</i>
			<i size="mini" @click="$Jump('/my/info/fileds')" v-if="$store.getters.getToken != ''" class="Red">个人中心</i>
			<i size="mini" @click="$Jump('/shop/apply')" v-if="$store.getters.getIdentity == 'buyer' || $store.getters.getToken == ''">开店申请</i>
			
			
			
			<i size="mini" @click="$Jump('/my/goods/goods_list')" v-if="$store.getters.getIdentity == 'seller'" class="Red">卖家中心</i>
			
			<i size="mini" @click="$Jump('/help/index')"><b style="font-weight: normal;margin-right: 5px;" class="el-icon-help"></b>帮助中心</i>
			
			<i size="mini" @click="Logout()" v-if="$store.getters.getToken != ''">退出</i>
			
		</div>
	</div>
	
	<el-dialog
	  title="消息"
	  :visible="MessageDialog"
	  width="800px"
	  :before-close="NoticeListDialogClose">
		<div class="Notices" v-if="NoticeList.length > 0">
				<vuescroll :ops="vuescroll_ops">
					<div class="NoticeList">
						<li v-for="(notice,noticeI) in NoticeList" :key="noticeI"><label><label>[{{notice.CreatedAt}}]</label>{{notice.Title}}</label> 
							<span v-if="notice.Status == 50" @click="ViewNotice(notice)">查看</span>	
							<i v-else-if="notice.Status == 60">已读</i>	
						</li>
					</div>
				</vuescroll>
		</div>
		<div v-else style="text-align: center;padding-bottom: 20px;">
			<i class="el-icon-warning"></i>消息箱中的信息为空
		</div>
	  </el-dialog>
	
  </div>
</template>

<script>
	import {  Button,Dialog } from 'element-ui';
	import vuescroll from 'vuescroll'
export default {
  name: 'Header',
  props: {
    msg: String
  },
  data(){
	  return{
		  MessageDialog:false,
		  NoticeList:[],
		  IsNoticeUnread:false,//是否存在未读消息
		  vuescroll_ops: {
		  	vuescroll: {},
		  	scrollPanel: {},
		  	rail: {
		  	},
		  	bar: {
		  		onlyShowBarOnScroll:false,
		  		background: '#000000',
		  		opacity: 0.1,
		  	}	
		  },
	  }
  },
  components: {
  	  "el-dialog":Dialog,
	  vuescroll
  },
  created() {
	  this.GetNoticeList()
	  let that = this
	  setInterval(function(){
	  	that.GetNoticeList()
	  },20000)
  },
  methods:{
	  Logout(){
		  localStorage.setItem('LoginData','')
		  this.$store.dispatch('setToken','')
		  this.$store.dispatch('setUserName','游客')
		  this.$store.dispatch('setIdentity','')
		  this.$store.dispatch('setIdentityName','')
		  this.$store.dispatch('setShopId','')
		  this.$store.dispatch('setTroopId','')
		  this.$store.dispatch('setCartItems',0)
		  this.$Jump('/')
	  },
	  NoticeListDialogClose(){
		this.MessageDialog = false  
	  },
	  OpenNoticeListDialog(){
		  if(this.$store.getters.getToken == ''){
				return
		  }
		  this.MessageDialog = true  
	  },
	  GetNoticeList(){
	  		  
	  		  if(this.$store.getters.getToken == ''){
	  			  return
	  		  }
	  			  
	  		  let data = { //一般采购账户只能获取自己的采购额度
	  				Service:'User',
	  				Class: 'Notice',
	  				Action: 'List',
					Page:1,
					PageSize:100,
	  				LoadingText:'none',
	  		  }
	  		  		  					  
	  		  this.$post(this.$store.getters.getApiHost,data)
	  		  .then((res) => {
	  		  		  				
	  				if(res.ErrorId == 0){
	  					if(res.Data.NoticeList != undefined && res.Data.NoticeList != null){
	  						this.NoticeList = res.Data.NoticeList
							this.IsNoticeUnread = false
							for(var i=0;i<this.NoticeList.length;i++){
								if(this.NoticeList[i].Status == 50){
									this.IsNoticeUnread = true
								}
							}
	  					}
	  				}
					
	  		  		  				
	  		  })
	  		  .catch(function (response) {
	  		  	this.$message('网络请求错误')
	  		  })
	  },
	  ViewNotice(_notice){

	  		  if(this.$store.getters.getToken == ''){
	  			  return
	  		  }
			  if(_notice.Status != 50){
				  this.$Jump(_notice.PcJumpUrl)
				  return
			  }
	  		  let data = { //一般采购账户只能获取自己的采购额度
	  				Service:'User',
	  				Class: 'Notice',
	  				Action: 'View',
	  				ViewId:_notice.Id,
	  				LoadingText:'none',
	  		  }
	  		  		  					  
	  		  this.$post(this.$store.getters.getApiHost,data)
	  		  .then((res) => {
	  		  		  				
	  				if(res.ErrorId == 0){
						this.GetNoticeList() //重取消息
	  					this.$Jump(_notice.PcJumpUrl)
	  				}
	  		  		  				
	  		  })
	  		  .catch(function (response) {
	  		  	this.$message('网络请求错误')
	  		  })
	  },
  }
}
</script>

<style scoped>
.Top{
	background-color: #f2f2f2;
	display: flex;
	height: 30px;
	line-height: 30px;
	color: rgba(0,0,0,0.4);
	font-size: 0.9rem;
	border-bottom: 1px solid rgba(0,0,0,0.06);
}
.Top .Home{
	width: 100px;
}
.Top .Home i{
	cursor: pointer;
}
.Top .Nav{
	flex: 1;
	text-align: right;
}
.Top .Nav i{
	padding-left: 15px;
	border-left: 1px solid rgba(0,0,0,0.2);
	margin-left: 15px;
	font-style: normal;
	cursor: pointer;
}
.Top .Nav i:hover{
	text-decoration: underline;
}

.Top .Nav i.Red{
	color: rgba(25,135,66,1);
}
.Notices{
	max-height: 400px;	
}
.NoticeList li{
	display: flex;
	padding-bottom: 10px;
	padding-right: 20px;
}
.NoticeList li:hover{
	color: rgba(200,0,0,1);
}
.NoticeList li label{
	flex: 1;
	cursor: pointer;
	font-size: 1.1rem;
}
.NoticeList li label label{
	margin-right: 10px;
	color: rgba(0,0,0,0.3);
}
.NoticeList li span{
	background-color: rgba(200,0,0,1);
	color: #FFFFFF;
	padding: 2px 10px;
	cursor: pointer;
}
.NoticeList li i{
	background-color: rgba(0,0,0,0.1);
	color: #999999;
	padding: 2px 10px;
	font-style: normal;
}
</style>
