export default {
  state: {
    IsLeftMenuCollapse: false, //是否收缩左侧菜单
	GlobalChannel: '', //全局频道,通过此变量控制各个频道所需的个性处理
  },
  mutations: { // store.commit('increment')
    SIsLeftMenuCollapse(state, str) {
      state.IsLeftMenuCollapse = str
    },
	SGlobalChannel(state, str) {
	  state.GlobalChannel = str
	},
  }, 
  actions: { // store.dispatch('incrementFormActions')
    setIsLeftMenuCollapse (context, str) {
      context.commit('SIsLeftMenuCollapse', str)
    },
	setGlobalChannel (context, str) {
	  context.commit('SGlobalChannel', str)
	},
  },
  getters: {
	getIsLeftMenuCollapse: state => {
	  return state.IsLeftMenuCollapse
	},
	getGlobalChannel: state => {
	  return state.GlobalChannel
	},
  }
}
