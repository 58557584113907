<template>
  <div class="Header">
    
	<div class="Wrap">
		
		<ul style="padding: 10px 0px;">
			<div class="Left">
				<div class="Logo" @click="$Jump('/')">
					<img src="../assets/img/common/logo.jpg" />
				</div>
			</div>
			
			<div class="Right">
				
				<div class="TopSearch">
					<el-input placeholder="请输入内容" size="small" v-model="SearchInput" class="input-with-select" style="width: 400px;height:35px;border: 2px solid rgba(25,135,66,1);border-radius: 0px;" @keyup.enter.native="Search"></el-input>
					<el-button size="small" type="primary" icon="el-icon-search" style="background-color: rgba(25,135,66,1);border-color: rgba(25,135,66,1);color: #FFFFFF;margin-left: 5px;border-radius: 0px;height: 39px;margin-left: 0px;padding-left: 30px;padding-right: 30px;font-size: 1.3rem;" @click="Search()">搜 索</el-button>
				</div>
				
			</div>
		</ul>
		
		<ul class="HeaderMenus">
			
			<span v-if="$route.name == 'Home'" :class="'On'" style="margin: 0px;padding: 0px;width: 200px;display: flex;align-items: center;">
				<em class="el-icon-s-grid" style="margin-left: 20px;"></em>
				全部分类</span>
				
				<span v-if="$route.name != 'Home'" :class=" $route.name != 'GoodsList' && $route.name != 'CouponIndex' && $route.name != 'AppDownload' && $route.name != 'NewsList'  && $route.name != 'NewsInfo' ? 'On':'' " @click="$Jump('/')">
					<em class="el-icon-s-home"></em>
					首页</span>
				
			<span v-for="menu in Menus" :key="menu.Id" :class="$route.query.hlc == menu.HighlightCode ? 'On':'' " @click="$Jump(menu.Uri)">{{menu.Name}}</span>

			<div class="Nav">
				
				
				<el-popover
				trigger="hover"
				  placement="bottom"
				  width="250">
				  <div>
					  <img style="width: 250px;" src="../assets/img/common/miniprog.jpeg" />
				  </div>
				<span style="margin-right: 5px;" slot="reference">
					<i class="el-icon-mobile-phone"></i>
					小程序版</span>
				</el-popover>
				
				
				<el-button slot="append"  size="mini" icon="el-icon-shopping-cart-2" style="background-color: rgba(25,135,66,1);border-color: rgba(25,135,66,1);color: #FFFFFF;border-radius: 0px 0px 0px 0px;" @click="Block('/shoppingcart')">购物车<i class="CartItems" v-if="$store.getters.getCartItems > 0">{{$store.getters.getCartItems}}</i></el-button>
			</div>
			
		</ul>
		
	</div>
	
  </div>
</template>

<script>
	import {  Button,Input,Select,Option,OptionGroup,Popover } from 'element-ui';
export default {
  name: 'Header',
  props: {
    msg: String
  },
  data() {
      return {
        SearchPlatform: 0,
		SearchInput:'',
		Menus:[],//导航菜单
      }
    },
  components: {
  	  "el-button":Button,
	  "el-input":Input,
	  'el-popover':Popover
  },
  computed: {
  	TopKeywords() {
  		return this.$store.getters.getTopKeywords;
  	},
  },
  watch:{
	 TopKeywords(v){
	 	if(v == ''){
			this.SearchInput = ''
		}
	 },
  },
  created() {
  	this.SearchInput = this.$store.getters.getTopKeywords
	
	let menuLocalStr = localStorage.getItem("HeaderMenus")
	if(menuLocalStr != null && menuLocalStr.length >= 2){
		this.Menus = JSON.parse(menuLocalStr)
	}
	this.GetMenus()
  },
  methods:{
	  ShowTips(){
		  this.$Jump('/login')
	  },
	  Block(_url){
		if(this.$store.getters.getToken == ''){
			this.$Jump('/login')
			return
		}
		this.$Jump(_url)
	  },
	  Search(){
		
		let q = {}
		q.keywords = this.SearchInput
		this.$router.push({path:"/goods/goods_list",query:q})
		this.$store.dispatch('setTopKeywords',this.SearchInput)
		
	  },
	  GetMenus(){
	  		
	  		let data = { //一般采购账户只能获取自己的采购额度
				Service:'User',
				Class: 'Menu',
				Action: 'List',
				Skey:'header_menus',
				Status:50,
				Page:1,
				PageSize:8,
				LoadingText:'none',
	  		}
	  				  					  
	  		this.$post(this.$store.getters.getApiHost,data)
	  		.then((res) => {
	  				  				
				if(res.ErrorId != 0){
					this.$message.error('导航菜单读取错误')
					return
				}
				
				this.Menus = res.Data.MenuList
				
				localStorage.setItem("HeaderMenus",JSON.stringify(res.Data.MenuList))
	  				  				
	  		})
	  		.catch(function (response) {
	  			this.$message('网络请求错误')
	  		})
	  		
	  }
  }
}
</script>

<style scoped>
.Header{
	background-color: #FFFFFF;
	border-bottom: 2px solid rgba(25,135,66,1);
}
.Logo{
	cursor: pointer;
}
.Header .Wrap  ul{
	width: 100%;
	display: flex;
}
.Header .Wrap .Left{
	width: 300px;
	display: flex;
	align-items: center;
}

.Header .Wrap .Right{
	flex: 1;
	display: flex;
}
.Header .Wrap .Right .TopSearch{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
 .Header .Wrap .Right .TopSearch /deep/ .el-input__inner {
    height: 35px;
    font-size: 13px;
    box-shadow: none;
    border: 0px solid rgba(0,0,0,0);
}
 
.Header .Wrap .Right .TopSearch /deep/ .el-input__inner:hover {
    border-color: rgba(0,0,0,0);
}
 
.Header .Wrap .Right .TopSearch /deep/ .el-input__inner:focus {
    border-color: rgba(0,0,0,0);
    box-shadow: none;
    transition-duration: .5s;
}
 
.Header .Wrap .Right .TopSearch /deep/ .el-input__inner::-webkit-input-placeholder {
    line-height: 35px;
}
 
.Header .Wrap .Right .TopSearch /deep/ .el-input__inner, .el-checkbox__inner, .el-textarea__inner, .el-button {
    border-radius: 0;
}


.Header .Wrap .Right .Nav{
	flex: 1;
	display: flex;
	align-items: center;
	flex-direction: row-reverse;
}
.HeaderMenus{
	display: flex;
}
.HeaderMenus span,.HeaderMenus a{
	padding: 7px 25px;
	cursor: pointer;
	font-size: 1.15rem;
	color: rgba(0,0,0,0.7);
}
.HeaderMenus span:hover,.HeaderMenus a:hover{
	color: rgba(25,135,66,1);
}
.HeaderMenus span.On,.HeaderMenus a.On{
	background-color: rgba(25,135,66,1);
	color: #FFFFFF;
}
.HeaderMenus span:hover .On,.HeaderMenus a:hover .On{
	color: #FFFFFF;
}
.HeaderMenus .Nav{
	flex: 1;
	display: flex;
	justify-content: flex-end;
}
.CartItems{
	background-color: #FFFFFF;
	color: rgba(23,134,65,1);
	display: inline-block;
	font-style: normal;
	height: 16px;
	line-height: 16px;
	margin-left: 5px;

	padding: 0px 4px;
}
</style>
