/*引入Vue框架*/
import Vue from 'vue';
/*引入资源请求插件*/
import axios from 'axios';
import router from '../Router';
import { Message,Loading } from "element-ui";
import store from '../store/index.js';
import qs from 'qs';
import 'element-ui/lib/theme-chalk/message.css'

axios.defaults.timeout = 3000;

let loadingInstance;

//http request 拦截器
axios.interceptors.request.use(
  config => {
	  
	  let loadingText = '加载中..'
	  if(config.LoadingText != undefined && config.LoadingText != null){
		  loadingText = config.LoadingText
	  }
	  
	  if(loadingText != "none"){ //通过传入NONE，让网络请求禁止展示loading
		//loading
		loadingInstance  = Loading.service({ //加载loading
			fullscreen: true, 
			text: loadingText,
			spinner: 'el-icon-loading',
			background: 'rgba(0, 0, 0, 0.55)' 
		});
	  }
	  
    // config.data = JSON.stringify(config.data); //这句拦截会把所有的请求对象转为JSON字符串
    config.headers = {
      'Content-Type':'application/x-www-form-urlencoded',
      //'Authorization':store.getters.getToken
    }
    // config.params = {'from':baidu } // url?from=baidu
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

//http response 拦截器
axios.interceptors.response.use(
  response => {
	  
	  if(loadingInstance){
		loadingInstance.close();//关闭loading
	  }
	 
	  
    if(response.data.ErrorId == 120098 || response.data.ErrorId == 201003006){ //强制登录
		Message('您尚未登录，登录后回到当前页')
		localStorage.setItem('LoginData','')
		
		store.dispatch('setToken','')
		store.dispatch('setUserName','')
		store.dispatch('setIdentity','')
		store.dispatch('setIdentityName','')
		store.dispatch('setShopId','')
		store.dispatch('setTroopId','')
		
		setTimeout(function(){
			router.push('/login')
		},2000)
        return
    }
    return response;
  },
  error => {
	  if(loadingInstance){
	  		loadingInstance.close();//关闭loading
	  }
    return Promise.reject(error)
  }
)

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
function get(url,params={}){
  return new Promise((resolve,reject) => {
    axios.get(url,{
      params:params
    })
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      console.log('出错码',err)
      Message({
          showClose: true,
          message: '网络层出错，请联系技术核查',
          type: "error"
         });
      reject(err)
    })
  })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

function post(url,data = {}){
	
		data["AppId"]= 'wojiasx-pc';
		data["Token"]= store.getters.getToken;
		data["Version"]= '1.1';
		
		let postConfig = {timeout: 3000}
		if(data["AjaxTimeout"] != undefined && data["AjaxTimeout"] > 0){
			postConfig.timeout = data["AjaxTimeout"]
		}
		
		if(data["LoadingText"] != undefined && data["LoadingText"] != null){
			postConfig.LoadingText = data["LoadingText"]
		}
		
		//对MOCK唯一网址支持
		url += '/'+data.Service+'/'+data.Class+'/'+data.Action
		
		delete data.Service
		delete data.Class
		delete data.Action
		
		//对MOCK唯一网址支持END
	
   return new Promise((resolve,reject) => {
     axios.post(url,qs.stringify(data),postConfig)
          .then(response => {
            resolve(response.data);
          },err => {
          Message({
          showClose: true,
          message: '网络层出错，请联系技术核查',
          type: "error"
         });
            reject(err)
          })
   })
 }

  /**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

function put(url,data = {}){
	
	data["AppCode"]= store.getters.getAppCode;
	
  return new Promise((resolve,reject) => {
    axios.put(url,qs.stringify(data))
         .then(response => {
           resolve(response.data);
         },err => {
           reject(err)
         })
  })
}

 /**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

function patch(url,data = {}){
  return new Promise((resolve,reject) => {
    axios.patch(url,qs.stringify(data))
         .then(response => {
           resolve(response.data);
         },err => {
           reject(err)
         })
  })
}

/*使用axios插件*/
Vue.prototype.$post = post;
Vue.prototype.$get = get;
Vue.prototype.$put = put;
Vue.prototype.$patch = patch;

export default({
});