export default {
  state: {
    Carts: [], //购物车
	CartItems:0,//购物车商品数量
  },
  mutations: { // store.commit('increment')
    SCart(state, str) {
      state.Carts = str
    },
	SCartPush(state, str) {
	  state.Carts.push(str)
	},
	SCartItems(state, _v) {
	  state.CartItems = _v
	}
  }, 
  actions: { // store.dispatch('incrementFormActions')
    setCart (context, str) {
      context.commit('SCarts', str)
    },
	setCartPush (context, str) {
	  context.commit('SCartPush', str)
	},
	setCartItems(context, _v) {
	  context.commit('SCartItems', _v)
	},
  },
  getters: {
    getCarts: state => {
      return state.Carts
    },
	getCartItems: state => {
	  return state.CartItems
	},
  }
}
