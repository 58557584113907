export default {
  state: {
    TopKeywords: '', //顶部搜索关键词
  },
  mutations: { // store.commit('increment')
    STopKeywords(state, str) {
      state.TopKeywords = str
    },
  }, 
  actions: { // store.dispatch('incrementFormActions')
    setTopKeywords (context, str) {
      context.commit('STopKeywords', str)
    },
  },
  getters: {
    getTopKeywords: state => {
      return state.TopKeywords
    },
  }
}
