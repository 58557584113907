export default {
  state: {
    Purchase: [], //采购单
  },
  mutations: { // store.commit('increment')
    SPurchase(state, str) {
      state.Purchase = str
    },
  }, 
  actions: { // store.dispatch('incrementFormActions')
    setPurchase (context, str) {
      context.commit('SPurchase', str)
    },
  },
  getters: {
    getPurchase: state => {
      return state.Purchase
    },
  }
}
