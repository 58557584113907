import Vue from 'vue'
import Router from 'vue-router'


// 原页面跳转
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)
export default new Router({
  linkActiveClass: 'active', // 路由active状态
  routes: [
    {
		name:'Home',path: '/',meta: { title: '我嘉生鲜' },
		component: resolve => require(['@/pages/index/index'], resolve),
    },
    {
		name:'Login',
      path: '/login',meta: { title: '登录商城' },
      component: resolve => require(['@/pages/user/login'], resolve)
    },
	{
		name:'ShopApply',
	  path: '/shop/apply',meta: { title: '开店申请' },
	  component: resolve => require(['@/pages/shop/apply'], resolve)
	},
	{
		name:'HelpIndex',
	  path: '/help/index',meta: { title: '帮助中心' },
	  component: resolve => require(['@/pages/help/index'], resolve)
	},
	{
		name:'CouponIndex',
	  path: '/coupon/index',meta: { title: '优惠券' },
	  component: resolve => require(['@/pages/coupon/index'], resolve)
	},
	{
		name:'ShopInfo',
	  path: '/shop/:Id',meta: { title: '店铺详情' },
	  component: resolve => require(['@/pages/shop/shop_info'], resolve)
	},
	{
		name:'Register',
		path: '/register',meta: { title: '注册商城' },
		component: resolve => require(['@/pages/user/register'], resolve)
	},
    {
		name:'Forgot',
      path: '/forgot',meta: { title: '取回密码' },
      component: resolve => require(['@/pages/user/forgot'], resolve)
    },
    {
		name:'GoodsList',
      path: '/goods/goods_list',meta: { title: '商品列表' },
      component: resolve => require(['@/pages/goods/goods_list'], resolve)
    },
    {
		name:'GoodsDetail',
      path: '/goods/detail/:Id',meta: { title: '商品详情' },
      component: resolve => require(['@/pages/goods/goods_detail'], resolve)
    },
	{
		name:'GroupBargaining',
	  path: '/group_bargaining/:GroupBargainingId',meta: { title: '拼团商品' },
	  component: resolve => require(['@/pages/goods/group_bargaining'], resolve)
	},
    {
		name:'Shoppingcart',
      path: '/shoppingcart',meta: { title: '购物车' },
      component: resolve => require(['@/pages/goods/shoppingcart'], resolve)
    },
    {
		name:'Buy',
      path: '/buy/:CartIds',meta: { title: '订单确认' },
      component: resolve => require(['@/pages/goods/buy'], resolve)
    },
	{
		name:'OrderPrepay',
	  path: '/order/prepay/:OrderIds',meta: { title: '订单支付' },
	  component: resolve => require(['@/pages/goods/prepay'], resolve)
	},
	{
		name:'NewsList',
		path: '/news/list/:CategoryId',meta: { title: '资讯列表' },
		component: resolve => require(['@/pages/news/news_list'], resolve)
	},
    {
		name:'NewsInfo',
      path: '/news/info/:Id',meta: { title: '资讯详情' },
      component: resolve => require(['@/pages/news/news_info'], resolve)
    },
	{
		name:'About',
		path: '/about/:Key',meta: { title: '关于' },
		component: resolve => require(['@/pages/about/index'], resolve),
	},
    {
		name:'My',
		path: '/my',meta: { title: '个人中心' },
		component: resolve => require(['@/pages/my/dispatch'], resolve),
		children:[
			{
				name:'GoodsClassificationList',
				path: 'goods/goods_classification_list',meta: { title: '商品分组列表' },
				component: resolve => require(['@/pages/my/goods/goods_classification_list'], resolve),
			},
			{
				name:'GoodsClassificationInfo',
				path: 'goods/goods_classification_info/:Id',meta: { title: '商品分组详情' },
				component: resolve => require(['@/pages/my/goods/goods_classification_info'], resolve),
			},
			{
				name:'MyExpressFeeTemplates',
				path: 'express_fee_template/list',meta: { title: '运费模板列表' },
				component: resolve => require(['@/pages/my/shop/express_fee_templates'], resolve),
			},
			{
				name:'MyExpressFeeTemplate',
				path: 'express_fee_template/info/:Id',meta: { title: '运费模板详情' },
				component: resolve => require(['@/pages/my/shop/express_fee_template'], resolve),
			},
			{
				name:'MyGoodsList',
				path: 'goods/goods_list',meta: { title: '商品列表' },
				component: resolve => require(['@/pages/my/goods/goods_list'], resolve),
			},
			{
				name:'MyGoodsInfo',
				path: 'goods/goods_info/:Id',meta: { title: '商品详情' },
				component: resolve => require(['@/pages/my/goods/goods_info'], resolve),
			},
			{
				name:'MyOrders',
				path: 'order/list',meta: { title: '订单列表' },
				component: resolve => require(['@/pages/my/order/order_list'], resolve),
			},
			{
				name:'MyCouponList',
				path: 'coupon/coupon_list',meta: { title: '我的优惠券' },
				component: resolve => require(['@/pages/my/coupon/coupon_list'], resolve),
			},
			{
				name:'MyOrderAppealList',
				path: 'order/appeal_list',meta: { title: '订单售后列表' },
				component: resolve => require(['@/pages/my/order/order_appeal_list'], resolve),
			},
			{
				name:'MyOrderDetail',
				path: 'order/detail/:Id',meta: { title: '订单详情' },
				component: resolve => require(['@/pages/my/order/order_detail'], resolve),
			},
			{
				name:'MyOrderAftermarket',
				path: 'order/aftermarket',meta: { title: '售后' },
				component: resolve => require(['@/pages/my/order/order_aftermarket'], resolve),
			},
			{
				name:'NewAftermarket',
				path: 'order/appeal_new/:SonOrderId',meta: { title: '售后申诉' },
				component: resolve => require(['@/pages/my/order/order_appear_new'], resolve),
			},
			{
				name:'NewAftermarketInfo',
				path: 'order/appeal/:Id',meta: { title: '售后申诉' },
				component: resolve => require(['@/pages/my/order/order_appear'], resolve),
			},
			{
				name:'GoodsHistory',
				path: 'order/goods_history',meta: { title: '常购商品' },
				component: resolve => require(['@/pages/my/order/goods_history'], resolve),
			},
			{
				name:'WorkOrderList',
				path: 'workorder/list',meta: { title: '工单列表' },
				component: resolve => require(['@/pages/my/workorder/list'], resolve),
			},
			{
				name:'WorkOrderInfo',
				path: 'workorder/info/:Id',meta: { title: '工单信息' },
				component: resolve => require(['@/pages/my/workorder/info'], resolve),
			},
			{
				name:'InvoiceList',
				path: 'invoice/list',meta: { title: '发票列表' },
				component: resolve => require(['@/pages/my/invoice/invoice_list'], resolve),
			},
			{
				name:'InvoiceInfo',
				path: 'invoice/info/:Id',meta: { title: '发票信息' },
				component: resolve => require(['@/pages/my/invoice/invoice_new'], resolve),
			},
			{
				name:'AddressList',
				path: 'address/list',meta: { title: '地址列表' },
				component: resolve => require(['@/pages/my/address/address_list'], resolve),
			},
			{
				name:'AddressNew',
				path: 'address/info/:Id',meta: { title: '地址信息' },
				component: resolve => require(['@/pages/my/address/address_new'], resolve),
			},
			{
				name:'InfoFileds',
				path: 'info/fileds',meta: { title: '个人资料' },
				component: resolve => require(['@/pages/my/info/fileds'], resolve),
			},
			{
				name:'FavoriteGoodsList',
				path: 'favorite/goods_list',meta: { title: '商品收藏列表' },
				component: resolve => require(['@/pages/my/favorite/goods_list'], resolve),
			},
			{
				name:'FavoriteShopList',
				path: 'favorite/shop_list',meta: { title: '店铺收藏列表' },
				component: resolve => require(['@/pages/my/favorite/shop_list'], resolve),
			},
			{
				name:'ShopOrders',
				path: 'shop/order_list',meta: { title: '店铺订单列表' },
				component: resolve => require(['@/pages/my/shop/order_list'], resolve),
			},
			{
				name:'ShopOrdersDetail',
				path: 'shop/order_detail/:Id',meta: { title: '店铺订单详情' },
				component: resolve => require(['@/pages/my/shop/order_detail'], resolve),
			},
			{
				name:'ShopCoupons',
				path: 'shop/coupon_list',meta: { title: '店铺优惠券列表' },
				component: resolve => require(['@/pages/my/shop/coupon_list'], resolve),
			},
			{
				name:'ShopCouponDetail',
				path: 'shop/coupon_detail/:Id',meta: { title: '店铺优惠券详情' },
				component: resolve => require(['@/pages/my/shop/coupon_info'], resolve),
			},
			{
				name:'ShopSetting',
				path: 'shop/shop_setting',meta: { title: '店铺设置' },
				component: resolve => require(['@/pages/my/shop/shop_setting'], resolve),
			},
			{
				name:'BrandList',
				path: 'shop/brand_list',meta: { title: '店铺品牌列表' },
				component: resolve => require(['@/pages/my/shop/brand_list'], resolve),
			},
			{
				name:'BrandInfo',
				path: 'shop/brand_info/:Id',meta: { title: '店铺品牌详情' },
				component: resolve => require(['@/pages/my/shop/brand_info'], resolve),
			},
			{
				name:'FinanceIndex',
				path: 'finance/index',meta: { title: '财务中心' },
				component: resolve => require(['@/pages/my/finance/finance_index'], resolve),
			},
			{
				name:'WithdrawAccountList',
				path: 'finance/withdraw_account_list',meta: { title: '提现账号列表' },
				component: resolve => require(['@/pages/my/finance/withdraw_account_list'], resolve),
			},
			{
				name:'WithdrawAccountInfo',
				path: 'finance/withdraw_account_info/:Id',meta: { title: '提现账号详情' },
				component: resolve => require(['@/pages/my/finance/withdraw_account_info'], resolve),
			},
			{
				name:'Safty',
				path: 'safty',meta: { title: '安全中心' },
				component: resolve => require(['@/pages/my/safty/index'], resolve),
			},
			{
				name:'PasAccount',
				path: 'safty/pas_account',meta: { title: '账户密码' },
				component: resolve => require(['@/pages/my/safty/pas_account'], resolve),
			},
			{
				name:'PasSafty',
				path: 'safty/pas_safty',meta: { title: '账户二级密码' },
				component: resolve => require(['@/pages/my/safty/pas_safty'], resolve),
			},
			{
				name:'PasPay',
				path: 'safty/pas_pay',meta: { title: '账户二级密码' },
				component: resolve => require(['@/pages/my/safty/pas_pay'], resolve),
			},
			{
				name:'Department',
				path: 'department',meta: { title: '部门管理' },
				component: resolve => require(['@/pages/my/organization/department'], resolve),
			},
			{
				name:'DepartmentMembers',
				path: 'department/members',meta: { title: '成员管理' },
				component: resolve => require(['@/pages/my/organization/members'], resolve),
			},
			{
				name:'Quota',
				path: 'department/quota',meta: { title: '额度配置' },
				component: resolve => require(['@/pages/my/organization/quota'], resolve),
			},
			{
				name:'BillList',
				path: 'bill/bill_list',meta: { title: '对账列表' },
				component: resolve => require(['@/pages/my/bill/bill_list'], resolve),
			},
			{
				name:'BillDetails',
				path: 'bill/bill_details',meta: { title: '对账详情' },
				component: resolve => require(['@/pages/my/bill/bill_details'], resolve),
			},
			{
				name:'ExportList',
				path: 'export/export_list',meta: { title: '导出清单' },
				component: resolve => require(['@/pages/my/export/export_list'], resolve),
			},
			{
				name:'SellerAppealList',
				path: 'seller/appeal_list',meta: { title: '售后列表' },
				component: resolve => require(['@/pages/my/seller/appeal_list'], resolve),
			},
			{
				name:'SellerAppeal',
				path: 'seller/appeal/:Id',meta: { title: '售后详情' },
				component: resolve => require(['@/pages/my/seller/appeal'], resolve),
			}
		]
    }
  ]
})
