import Mock from 'mockjs'
 
 import goods from './goods.js'
 
const apiHost = 'http://localhost:1350/Api'
const data={ 
    "id":"@guid",
    "name":"@cname",
};
 
const goodsPlatform={ 
    "id":"@guid",
    "name":"@cname",
};
 
Mock.mock(apiHost+'?Service=yt.ec&Class=Goods&Action=List', 'post', data)

Mock.mock(apiHost+'?Service=yt.ec&Class=Shop&Action=Platforms', 'post', goods.Platforms)
 
export default Mock;