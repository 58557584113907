//公共方法
import Vue from 'vue';


function Jump(_url,_para = {}){
	
	this.$router.push({  //核心语句
		path:_url,   //跳转的路径
		query:_para
	  })
	
}

function Jump2(_url){
	
	location.href = _url
	
}

//字符串 前保留 后保留 加星数
function StrStar(str){
	
	return str.replace(/^(\d{3})\d{4}(\d+)/,"$1****$2")
	
}

//取出首尾空格
function Trim(str){
	return str.replace(/(^\s*)|(\s*$)/g, "")
}

function AdJump(_ad){
	
	console.log('点击对象',_ad)
	if(_ad.JumpType == undefined || _ad.JumpType == null){
		return
	}
	
	if(_ad.JumpType == ''){
		return
	}
	
	if(_ad.JumpTarget == undefined || _ad.JumpTarget == null){
		return
	}
	
	//商品跳转
	if(_ad.JumpType == 'goodsId'){
		
		this.$router.push({path:'/goods/detail/'+_ad.JumpTarget,query:{} })
		return
		
	}
	
	if(_ad.JumpType == 'newsId'){
		
		this.$router.push({path:'/news/info/'+_ad.JumpTarget,query:{} })
		return
		
	}
	
	if(_ad.JumpType == 'uri'){
		
		this.$router.push({path:_ad.JumpTarget,query:{} })
		return
		
	}
	
	if(_ad.JumpType == 'url'){
		
		window.location = _ad.JumpTarget;
		return
		
	}
	
}

Vue.prototype.$Jump = Jump
Vue.prototype.$Jump2 = Jump2
Vue.prototype.$StrStar = StrStar
Vue.prototype.$Trim = Trim
Vue.prototype.$AdJump = AdJump
export default({
})