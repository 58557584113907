import Vue from 'vue'
import App from './App.vue'
import "babel-polyfill"
import router from './Router'
import store from './store'
import 'element-ui/lib/theme-chalk/icon.css'
import axios from './third/axios'
import commonFunc from './third/common'
import Mock from '@/mock'
import '@/assets/css/common.scss';

import {  Input,Button,Message,MessageBox } from 'element-ui';

import TopNav from '@/components/Top.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'


Vue.use(axios) //联网请求规则，<0的业务由框架完成(除登陆外)，0和>0的业务由逻辑完成
Vue.use(commonFunc)

Vue.component('el-button',Button)
Vue.component('el-input',Input)
Vue.component('TopNav',TopNav)
Vue.component('Header',Header)
Vue.component('Footer',Footer)

Vue.config.productionTip = false

Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm;

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  // 让页面回到顶部
   document.documentElement.scrollTop = 0
  next()
})


new Vue({
	router,
  store,
  render: h => h(App)
}).$mount('#app')
