<template>
  <div id="app">

		<router-view/>

  </div>
  
</template>

<script>
export default {
  name: 'app',
  data() {
      return {
      }
  },
  computed: {
	SuperStop(){
		this.ss()
		return ''
	},
  	IsMyCenter() {
		let ret = false
		let centerRouterNames = [
			'My',
			'MyOrders',
			'MyOrderDetail',
			'MyOrderAftermarket',
			'NewAftermarket',
			'GoodsHistory',
			'InvoiceList',
			'InvoiceNew',
			'AddressList',
			'AddressNew',
			'InfoFileds',
			'Safty',
			'PasAccount',
			'PasSafty',
			'PasPay',
			'Department',
			'DepartmentMembers',
			'Quota',
			'BillList',
			'BillDetails',
			'ExportList'
		]
		let temp = centerRouterNames.find(t => t == this.$route.name)
		if(temp != undefined){
			ret = true
		}
  		return ret
  	},
	
  },
  components: {
  },
  created() {
	
	//加载账户状态
	let LoginDataStr = localStorage.getItem('LoginData')
	if(LoginDataStr != null && LoginDataStr.length > 0){
		
		let loginDataObj = JSON.parse(LoginDataStr)
		this.$store.dispatch('setToken',loginDataObj.Token)
		this.$store.dispatch('setUserName',loginDataObj.Account)
		this.$store.dispatch('setIdentity',loginDataObj.Identity)
		this.$store.dispatch('setIdentityName',loginDataObj.IdentityName)
		this.$store.dispatch('setShopId',loginDataObj.ShopId)
		this.$store.dispatch('setTroopId',loginDataObj.TroopId)
		
		if(this.$store.getters.getToken.length > 20){
			this.GetCartCount()
		}
		
	} 
  },
  methods:{
	  GetCartCount(){
	  			  
		  //发起网络请求，添加购物清单
		  let data = {
			Service:'Goods',
			Class: 'ShoppingCart',
			Action: 'Count',
		  }
		  
		  this.$post(this.$store.getters.getApiHost,data)
		  .then((res) => {
			
			if(res.ErrorId != 0){
				this.$message(res.Msg)
				return
			}
			
			if(res.Data.Count > 0){
				this.$store.dispatch('setCartItems',res.Data.Count)
			}else{
				this.$store.dispatch('setCartItems',0)
			}
		
			
		  })
	  },
  }
}
</script>

<style>
	body,html{
		margin: 0px;
		padding: 0px;
		color: #333333;
		background-color: #f2f2f2;
		height: 100%;
		font: 14px/1.5 tahoma,arial,"\5b8b\4f53";
	}
	#app{
		height: 100%;
	}
	ul{
		padding: 0px;
	}
	li{
		list-style: none;
	}
	a{
		text-decoration: none;
		color: rgba(0,0,0,0.8);
	}
	em{
		font-style: normal;
	}
	ul{
		margin: 0px;
		padding: 0px;
	}
	.Wrap{
		width: 1280px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		flex-wrap: wrap;
	}
	.BGWhite{
		background-color: #FFFFFF;
	}
	.MarginTop20{
		margin-top: 20px;
	}
	.MarginTop10{
		margin-top: 10px;
	}
	.MarginBottom10{
		margin-bottom: 10px;
	}
	.PaddingBottom20{
		padding-bottom: 20px;
	}
	.Padding20{
		padding: 20px;
	}
	.Page{
		width: 100%;
		display: flex;
		justify-content: center;
		margin: 20px 0px;
	}
	.Page .el-pagination.is-background .el-pager li:not(.disabled).active {
	  background-color: rgba(255,94,97,1); 
	  color: #fff;
	}
	
	.el-cascader-panel{
		height: 450px;
	}
</style>
