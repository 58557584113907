export default {
  state: {
    ApiHost: 'https://api.wojiasx.com', 
		//http://localhost:29100
		//https://api.wojiasx.com
    FileHost: 'http://file.wojiasx.com',
    AppId: '0e0949ee-2b75-4328-9ff7-38d3983bc1ed', //应用的唯一ID
	HDYUrl: 'http://d.wojiasx.com', //官网地址运营主体等信息跳转时需要用到
	LoginDirect:'',//登录后的定向网址
	AppName:'我嘉生鲜',//应用名称
  },
  mutations: { // store.commit('increment')
    SApiHost (state, str) {
      state.Code = str
    },
    SFileHost (state, str) {
      state.FileHost = str
    },
    SAppId (state, str) {
      state.AppId = str
    },
	SetHDYUrl (state, str) {
	  state.HDYUrl = str
	},
	SetLoginDirect (state, str) {
	  state.LoginDirect = str
	},
	SetDiyHost (state, str) {
	  state.DiyHost = str
	},
	SetAppName (state, str) {
	  state.AppName = str
	}
  },
  actions: { // store.dispatch('incrementFormActions')
    setApiHost (context, str) {
      context.commit('SApiHost', str)
    },
    setFileHost (context, str) {
      context.commit('SFileHost', str)
    },
    setAppId (context, str) {
      context.commit('SAppId', str)
    },
		setHDYUrl (context, str) {
		  context.commit('SetHDYUrl', str)
		},
		setLoginDirect(context, str) {
		  context.commit('SetLoginDirect', str)
		},
		setDiyHost(context, str) {
		  context.commit('SetDiyHost', str)
		},
		setAppName(context, str) {
		  context.commit('SetAppName', str)
		},
  },
  getters: {
    getApiHost: state => {
      return state.ApiHost
    },
    getFileHost: state => {
      return state.FileHost
    },
    getAppCode: state => {
      return state.AppCode
    },
    getAppId: state => {
      return state.AppId
    },
    getHDYUrl: state => {
      return state.HDYUrl
    },
    getLoginDirect: state => {
      return state.LoginDirect
    },
    getDiyHost: state => {
      return state.DiyHost
    },
    getAppName: state => {
      return state.AppName
    }
  }
}