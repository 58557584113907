<template>
	
	<div>
		
		<!--全局右侧悬浮菜单-->
		<div class="FloatMenus">
			
			<div class="CenterMenu">
				<ul>
					
					<li @click="$Jump('/shoppingcart')">
						<el-popover
						    placement="left"
						    trigger="hover"
							width="60px">
							<div style="cursor: pointer;" @click="$Jump('/shoppingcart')">购物车</div>
							<i class="el-icon-shopping-cart-2" slot="reference"></i>
						  </el-popover>
					</li>
					
					
					<li @click="$Jump('/my/order/list')">
						<el-popover
						    placement="left"
						    trigger="hover"
							width="60px">
							<div style="cursor: pointer;"  @click="$Jump('/my/order/list')">我的订单</div>
							<i class="el-icon-goods" slot="reference"></i>
						  </el-popover>
					</li>
		
					<li @click="$Jump('/my/safty')">
						<el-popover
						    placement="left"
						    trigger="hover"
							width="60px">
							<div style="cursor: pointer;"  @click="$Jump('/my/safty')">个人中心</div>
							<i class="el-icon-user" slot="reference"></i>
						  </el-popover>
					</li>
					
					<li @click="$Jump('/about/contract')">
						<el-popover
						    placement="left"
						    trigger="hover"
							width="60px">
							<div style="cursor: pointer;"  @click="$Jump('/about/contract')">联系客服</div>
							<i class="el-icon-headset" slot="reference"></i>
						  </el-popover>
					</li>
					
					<li @click="ScrollToTop()" v-if="topBtnShow">
						<el-popover
						    placement="left"
						    trigger="hover"
							width="60px">
							<div style="cursor: pointer;"  @click="ScrollToTop()">返回顶部</div>
							<i class="el-icon-arrow-up" slot="reference"></i>
						  </el-popover>
					</li>
					
					
					
				</ul>
				
			</div>
			
		</div>
	
	<div class="Bottom">
		<div class="Service">
			<ul>
				<span>
					鲜
				</span>
				<div>
					<li>优质生鲜</li>
					<p>供应链品质保障</p>
				</div>
			</ul>
			<ul>
				<span>
					保
				</span>
				<div>
					<li>售后保障</li>
					<p>暖心售后保障</p>
				</div>
			</ul>
			<ul>
				<span>
					速
				</span>
				<div>
					<li>极速发货</li>
					<p>火速反应 快递速达</p>
				</div>
			</ul>
			<ul>
				<span>
					服
				</span>
				<div>
					<li>生鲜客服</li>
					<p>专注生鲜知识链</p>
				</div>
			</ul>
		</div>
		<div class="Guide">
			<div>
				<h6>购物指南</h6>
				<ul>
					<a href="/#/register" target="_blank">用户注册</a>
					<a href="/#/login" target="_blank">用户登录</a>
					<a href="/#/goods/goods_list" target="_blank">商品搜索</a>
					<a href="/#/my/order/list" target="_blank">订单管理</a>
				</ul>
			</div>
			<div>
				<h6>平台保障</h6>
				<ul>
					<span>发票保障</span>
					<span>售后保障</span>
					<span>客服保障</span>
				</ul>
			</div>
			<div>
				<h6>支付方式</h6>
				<ul>
					<span>微信支付</span>
					<span>余额支付</span>
				</ul>
			</div>
			<div>
				<h6>商家服务</h6>
				<ul>
					<a href="/#/shop/apply" target="_blank">开店申请</a>
					<a href="/#/my/shop/shop_setting" target="_blank">店铺管理</a>
					<a href="/#/my/goods/goods_list" target="_blank">店铺商品管理</a>
					<a href="/#/my/shop/order_list" target="_blank">店铺订单管理</a>
				</ul>
			</div>
			<div>
				<h6>小程序版</h6>
				<ul>
					<img class="AppDownload" src="../assets/img/common/miniprog.jpeg" />
				</ul>
			</div>
		</div>
		<div class="About">
			<div class="Navs">
				<a href="/#/about/us" target="_blank">关于我们</a>
				<a href="/#/about/contract" target="_blank">联系我们</a>
				<a href="/#/about/argument" target="_blank">用户协议</a>
				<a href="/#/news/list/all" target="_blank">网站资讯</a>
			</div>
			<div class="CopyRight">备案编号 沪ICP备20011720号-2
			<br>© 2018-2020 <b>{{$store.getters.getAppName}}</b> 版权所有 
			</div>
		</div>
	</div>
	

  </div>
</template>

<script>
	import {Popover} from "element-ui"
export default {
  name: 'Footer',
  props: {
    msg: String
  },
  data() {
      return {
  		visible:false,
		topBtnShow:false,
      }
    },
  components: {
  	  'el-popover':Popover,
  },
  mounted () {
  	window.addEventListener('scroll', this.handleScroll2)
  },
  destroyed () {
  	window.removeEventListener('scroll', this.handleScroll2)
  },
	methods:{
		handleScroll2 () {
					var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
					if(scrollTop >= 700){
						this.topBtnShow = true
					}else{
						this.topBtnShow = false
					}
		},
		  ScrollToTop(){
			 let timer = setInterval(() => {
			   window.scrollBy(0, -400)
			   if (document.documentElement.scrollTop === 0) {
			     clearInterval(timer)
			   }
			 }, 10)
		  }
	}
}
</script>

<style scoped>
.Footer{
	padding: 50px 0px 30px;
	background-color: #FFFFFF;
	text-align: center;
	border-top: 1px solid rgba(0,0,0,0.1);
}
.Footer .Wrap{
	flex-wrap: wrap;
}
.Footer .Wrap div{
	width: 100%;
}
.Navs{
	margin-bottom: 10px;
}
.Navs a{
	margin-left: 20px;
	margin-right: 20px;
	cursor: pointer;
	color: rgba(255,255,255,0.6);
}
.Navs a:hover{
	color: rgba(25,135,66,1);
}
.CopyRight{
	color: #888888;
	margin-bottom: 10px;
}



.FloatMenus{
	display: block;
	position: fixed;
	right: 0px;
	top: 0px;
	height: 100%;
	z-index: 1;
}
.CenterMenu{
	height: 100%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.CenterMenu ul{
	background-color: rgba(0,0,0,0.55);
	padding: 10px 0px;
	border-radius: 10px 0px 0px 10px;
}
.CenterMenu ul li{
	cursor: pointer;
	padding: 6px 10px;
	font-size: 1.3rem;
	color: rgba(255,255,255,0.8);
	cursor: pointer;
}
.CenterMenu ul li:hover{
	background-color: rgba(25,135,66,1);
	color: rgba(255,255,255,1.0);
}

.Bottom{
	background-color: #FFFFFF;
	border-top: 1px solid rgba(0,0,0,0.1);
	padding-top: 20px;
	margin-top: 30px;
}
.Bottom .Service{
	width: 1180px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
}
.Bottom .Service ul{
	display: flex;
	align-items: center;
}
.Bottom .Service ul span{
	font-size: 26px;
	height: 50px;
	width: 50px;
	display: block;
	line-height: 50px;
	text-align: center;
	border: 3px solid rgba(25,135,66,1);
	border-radius: 28px;
	font-weight: bold;
	color: rgba(25,135,66,1);
	margin-right: 10px;
}
.Bottom .Service ul div{
	
}
.Bottom .Service ul div li{
	font-size: 1.3rem;
	margin: 0px;
	padding: 0px;
}
.Bottom .Service ul div p{
	font-size: 1rem;
	margin: 0px;
	padding: 0px;
	color: rgba(0,0,0,0.5);
}

.Bottom .Guide{
	width: 1180px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	justify-content: space-between;
	border-top: 1px solid rgba(0,0,0,0.1);
	margin-top: 30px;
}
.Bottom .Guide div{
	flex: 1;
	margin-right: 20px;
	padding-top: 20px;
	color: rgba(0,0,0,0.65);
}
.Bottom .Guide div h6{
	font-size: 16px;
	font-weight: normal;
	margin: 0px;
	padding: 0px;
}
.Bottom .Guide div ul{
	margin-top: 10px;
}
.Bottom .Guide div ul span,.Bottom .Guide div ul a{
	display: block;
	cursor: pointer;
	color: rgba(0,0,0,0.4);
	margin-bottom: 3px;
}
.Bottom .Guide div ul span{
	cursor: default;
}
.Bottom .Guide .AppDownload{
	width: 100px;
	height: 100px;
}
.Bottom .About{
	border-top: 2px solid rgba(25,135,66,1);
	margin-top: 20px;
	padding: 10px 0px 20px 0px;
	background-color: #000000;
	color: rgba(255,255,255,0.6);
	text-align: center;
}
.Bottom .About .Navs{
	width: 1180px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 15px;
}
.Bottom .About .Navs span{
	margin-left: 0px;
	margin-right: 20px;
}
.Bottom .About .CopyRight{
	width: 1180px;
	margin-left: auto;
	margin-right: auto;
}
.Bottom .About .CopyRight b{
	color: rgba(25,135,66,1);
}
</style>
