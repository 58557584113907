const Platforms = [
	{
		  Id:'all',
		  Name:'全部'
	},
  {
	  Id:'sn',
	  Name:'苏宁'
  },
  {
  	  Id:'jd',
  	  Name:'京东'
  },
  {
  	  Id:'tm',
  	  Name:'天猫'
  },
  {
  	  Id:'gm',
  	  Name:'国美'
  }
]
const res = {
  Platforms
}
export default res