import Vuex from 'vuex'
import Vue from 'vue'

import User from './module_user.js'
import Config from './module_config.js'
import DoConf from './module_do.js'
import Purchase from './module_purchase.js'
import Cart from './module_cart.js'
import Data from './module_data.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user: User,
    config: Config,
	doconf:DoConf,
	purchase:Purchase,
	cart:Cart,
	data:Data
  }
})